* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  margin: 20px;
  padding: 0;
}

h1 {
  margin-top: 0;
  font-size: 22px;
}

h2 {
  margin-top: 0;
  font-size: 20px;
}

h3 {
  margin-top: 0;
  font-size: 18px;
}

h4 {
  margin-top: 0;
  font-size: 16px;
}

h5 {
  margin-top: 0;
  font-size: 14px;
}

h6 {
  margin-top: 0;
  font-size: 12px;
}

code {
  font-size: 1.2em;
}

ul {
  padding-inline-start: 20px;
}

* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  margin: 20px;
  padding: 0;
}

.number {
  background: #8f8f8f;
  border: 1px solid #292929;
  color: #fff;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 60px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 60px;
}

.toprow {
  background: #686868;
  border: 1px solid #292929;
  color: #fff;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 60px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 60px;
}

.operation {
  background: #ed9a15;
  border: 1px solid #292929;
  color: #fff;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 60px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 60px;
}

.operationpressed {
  background: #ed9a15;
  border: 3px solid #292929;
  color: #fff;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 60px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 60px;
}


.zero {
  background: #8f8f8f;
  border: 1px solid #292929;
  float: left;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 60px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 119px;
}

.calc-row:after {
  clear: both;
  content: '';
  display: table;
}

.calc-display {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  float: left;
  color: white;
  background: #383838;
  border: 1px solid #999;
  font-size: 50px;
  height: 60px;
  width: 237px;

}

.game {
  display: flex;
  flex-direction: row;
}

.game-info {
  margin-left: 20px;
}